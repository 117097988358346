import Layout from '@/components/layout/Layout';
import { FC } from 'react';
import Link from 'next/link';
import HomeNewBooks from '@/components/ui/home-new-books/HomeNewBooks';
import HomePopularBooks from '@/components/ui/home-popular-books/HomePopularBooks';
import HomeSearchBlock from '@/components/ui/home-search-block/HomeSearchBlock';
import HomeInfoBlock from '@/components/ui/home-info-block/HomeInfoBlock';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';

import styles from './Error.module.scss';

interface IError {
  popularBooks: IBookPage;
  newBooks: IBookPage;
  oneNewBook: IBooksPageData;
  onePopularBook: IBooksPageData;
}

const Error: FC<IError> = ({ popularBooks, newBooks, oneNewBook, onePopularBook }) => {
  //Seo block
  const SEO_TITLE = 'Страница не найдена – Ошибка 404';
  const SEO_DESCRIPTION = 'Запрошенной страницы нет на нашем сайте. Возможно, вы ввели неправильный адрес в строке браузера.';
  const SEO_CANONICAL = '/error';

  return (
    <Layout title={SEO_TITLE} description={SEO_DESCRIPTION} canonicalHref={SEO_CANONICAL} pageHref={SEO_CANONICAL}>
      <div className="content">
        <div className={styles.errorBlock}>
          <div className={styles.errorBlock__img}></div>
          <div className={styles.errorBlock__desk}>
            <h1 className={styles.errorBlock__title}>Извините, такой страницы нет…</h1>
            <div className={styles.errorBlock__txt}>Попробуйте вернуться назад и повторить еще раз, или выберите из предложенного ниже.</div>
            <Link prefetch={false} href="/" className={styles.errorBlock__btn}>
              Перейти на главную
            </Link>
          </div>
        </div>
      </div>
      <HomeNewBooks oneNewBook={oneNewBook} newBooks={newBooks} />
      <HomePopularBooks onePopularBook={onePopularBook} popularBooks={popularBooks} />
      <HomeSearchBlock />
      <HomeInfoBlock />
    </Layout>
  );
};

export default Error;
