import Error from '@/components/screens/error/Error';
import { NextPage, GetStaticProps } from 'next';
import { API_URL, REVALIDATE_TIME } from '@/helpers/variables';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';

interface IHomePage {
  popularBooks: IBookPage;
  newBooks: IBookPage;
  oneNewBook: IBooksPageData;
  onePopularBook: IBooksPageData;
}

const HomePage: NextPage<IHomePage> = ({ popularBooks, newBooks, oneNewBook, onePopularBook }) => {
  return <Error popularBooks={popularBooks} newBooks={newBooks} oneNewBook={oneNewBook} onePopularBook={onePopularBook} />;
};

export const getStaticProps: GetStaticProps = async () => {
  // Баг на бэке с новинками и популрными 500
  const [popularRes, newRes] = await Promise.all([fetch(`${API_URL}satellite/books/popular?page=1&page_size=20&sort_visited=true`), fetch(`${API_URL}satellite/books/news?page=1&page_size=12`)]);
  const [popularBooks, newBooks] = await Promise.all([popularRes.json(), newRes.json()]);

  const oneNewBook = newBooks.data.length > 0 ? newBooks.data.shift() : [];
  const onePopularBook = popularBooks.data.length > 0 ? popularBooks.data.shift() : [];

  return { props: { popularBooks, newBooks, oneNewBook, onePopularBook }, revalidate: REVALIDATE_TIME };
};

export default HomePage;
